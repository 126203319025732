<template>
  <div>
    <v-form>
      <p class="mb-3"></p>
      <v-simple-table class="mb-3">
        <tr>
          <th></th>
          <th style="text-align: left">ppm</th>
          <th style="text-align: left">Integration</th>
          <th style="text-align: left">Splitting</th>
        </tr>
        <tr v-for="option in tableOptions" :key="option">
          <td v-for="part in option" :key="part[0]">{{ part }}</td>
        </tr>
      </v-simple-table>

      <p v-for="question in questions" :key="question">
        {{ question.letter }}
        <v-select
          v-model="inputs[question.inputName]"
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
          class="mb-0 mt-0"
          style="width: 300px; display: inline-block"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2_M1Q6',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
        studentAnswerC: null,
      },
      tableOptions: [
        ['A', '3.70', '2 H', 'singlet'],
        ['B', '3.30', '3 H', 'singlet'],
        ['C', '1.25', '9 H', 'singlet'],
      ],
      questions: [
        {letter: 'A', inputName: 'studentAnswerA'},
        {letter: 'B', inputName: 'studentAnswerB'},
        {letter: 'C', inputName: 'studentAnswerC'},
      ],
      dropdownOptions: [
        {
          text: '$\\ce{a methylene - CH_2}$',
          value: 'CH2',
        },
        {
          text: '$\\ce{a methyl - CH3}$',
          value: 'CH3',
        },
        {
          text: '$\\text{a }tert\\ce{-butyl group - C(CH3)3}$',
          value: 'CCH33',
        },
        {
          text: '$\\ce{a methyne - CH}$',
          value: 'CH',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.dropdownOptions, this.seed);
    },
  },
};
</script>
